
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  computed,
  onMounted,
  onUnmounted
} from "vue";
import {useRouter} from "vue-router";
import {ElMessage, ElMessageBox} from "element-plus";
import {ElLoading} from "element-plus";
import axios from "@/api/http.js";
import footBar from "@/components/nav/aruba/footBar.vue";
import elq from '@/utils/arubaElq.js';

export default defineComponent({
  name: "Login",
  components: {
    footBar
  },
  setup() {
    const client = localStorage.getItem("eventClient");
    const route = useRouter(); // 第一步
    const data = reactive({
      userName: "",
      userPwd: "",
      showUserName: true,
      checkboxVal: false,
      showTip: false, //勾选提示
      tip: "",
      ssoSubffix: [],//有这些后缀的email会跳转到ssoLink
      ssoLink: ''//sso的登录网页
    });
    const showCheckBox = computed(() => {
      return data.showUserName;
    });

    const next = () => {
      if (data.userName == "") {
        data.tip = `Sorry, you're not currently approved to attend this event. Please contact <a href='mailto:arubasko@rockitevents.net'>arubasko@rockitevents.net</a> for more information.`;
        data.showTip = true;
      } else {
        if (data.checkboxVal) {
          const loading = ElLoading.service({
            lock: true,
          })
          axios
              .request({
                method: "POST",
                url: "m/check-email/",
                data: {
                  email: data.userName,
                  meetingId: "7pvonav7ez8g6u9ve7isxtcb",
                  type: "virtual"
                },
              })
              .then((res) => {
                console.log("res:", res);
                console.log("res.data.result:", res.data.result);
                if (res.data.result == "succeeded") {
                  loading.close();
                  // sso跳转判断
                  let isSSO = false;
                  let userNameToSmall = data.userName.toLowerCase();
                  data.ssoSubffix.some(item => {
                    let smallItem = item.toLowerCase();
                    console.log("====smallItem=====", smallItem)
                    if (userNameToSmall.indexOf(smallItem) > -1) {
                      isSSO = true;
                      console.log("====isSSO=====", isSSO)
                      if (client == 'Aruba') {
                        console.log("==== 跳转sso =====",)
                        const loading = ElLoading.service({
                          lock: true,
                        })
                        window.location.replace('digital/sso')
                      } else {
                        window.location.replace(data.ssoLink)
                      }
                      return true
                    }
                  })
                  if (isSSO) {
                    return
                  } else {
                    data.showUserName = false;
                    data.showTip = false;
                  }
                }
              })
              .catch((err) => {
                loading.close();
                data.tip = err.response.data.error;
                if (!data.tip || data.tip == 'list index out of range') {
                  data.tip = 'Sorry, you\'re not currently registered for this event. Please click the register here hyperlinked text to the left to register.';
                }
                data.showTip = true;
                // ElMessageBox.confirm(
                //     err.response.data.error,
                //     {
                //       confirmButtonText: 'Yes',
                //       // cancelButtonText: "Cancel",
                //       type: 'warning',
                //     }
                // );
              });
        } else {
          data.tip = "You must accept the Terms of Use to proceed";
          data.showTip = true;
          // ElMessageBox.confirm(
          //     'You must accept the Terms of Use to proceed.',
          //     'Confirmation',
          //     {
          //       confirmButtonText: 'Yes',
          //       // cancelButtonText: "Cancel",
          //       type: 'warning',
          //     }
          // );
        }
      }
    };
    const login = () => {
      if (data.userName == "") {
        data.tip = "Please input your password";
        data.showTip = true;
        // ElMessageBox.confirm(
        //     'Please input your password',
        //     {
        //       confirmButtonText: 'Yes',
        //       // cancelButtonText: "Cancel",
        //       type: 'warning',
        //     }
        // );
      } else {
        const loading = ElLoading.service({
          lock: true,
        })
        axios
            .request({
              method: "POST",
              url: "/m/authenticate/",
              data: {
                username: data.userName,
                password: data.userPwd,
              },
            })
            .then((res) => {
              loading.close();
              console.log("====login success！=====", res.data)
              if (res.data.result == "succeeded") {

                localStorage.elqEmail = data.userName;
                localStorage.info = JSON.stringify(res.data);
                localStorage.webToken = res.data.token;
                let campusInfo = res.data;
                localStorage.campusInfo = JSON.stringify(res.data);
                console.log('localStorage.redirectUri', localStorage.redirectUri);
                elq.elqActivityLogin();
                if (localStorage.redirectUri != '' && localStorage.redirectUri != undefined) {
                  console.log('redirectUri', 'localStorage.redirectUri', localStorage.redirectUri)
                  if (localStorage.redirectUriQuery != '' && localStorage.redirectUriQuery != undefined) {
                    console.log('redirectUriQuery', 'localStorage.redirectUriQuery', localStorage.redirectUriQuery)
                    let q = JSON.parse(localStorage.redirectUriQuery);
                    route.push({
                      name: localStorage.redirectUri,
                      query: q,
                    });
                  } else {
                    console.log('3333')
                    route.push({name: localStorage.redirectUri});
                  }
                } else {
                  console.log('4444')
                  route.push({name: "Home"});
                }
                // route.push({name: 'Mec'});
                // route.push({name: "Home"});
              } else {
                console.log("==== result=====", res.data.error);
                data.tip = res.data.error;
                data.showTip = true;
                // ElMessageBox.confirm(
                //     res.data.error,
                //     {
                //       confirmButtonText: 'Yes',
                //       // cancelButtonText: "Cancel",
                //       type: 'warning',
                //     }
                // );
              }
            })
            .catch((err) => {
              console.log(err);
              loading.close();
              data.tip = err.response.data.error;
              data.showTip = true;
              // ElMessageBox.confirm(
              //     err.response.data.error,
              //     {
              //       confirmButtonText: 'Yes',
              //       // cancelButtonText: "Cancel",
              //       type: 'warning',
              //     }
              // );
            });
      }
    };
    const fun = () => {
    };
    const getMConfig = () => {
      axios.request({
        method: 'GET',
        url: '/m/config',
      }).then(res => {
        console.log('cmsConfig!!!!!!!!:', res.data.ssoLink);
        data.ssoSubffix = res.data.ssoSubffix;
        data.ssoLink = res.data.ssoLink;
      }).catch(err => {
        console.log(err);
      })
    }
    getMConfig();
    const listenKeyDown = (e) => {
      if (e.keyCode === 13) {
        if (data.showUserName) {
          next();
        } else {
          login();
        }
      }
    }
    const nextStep = () => {
      if (data.showUserName) {
        next();
      } else {
        login();
      }
    }
    const goLink = (url) => {
      console.log("==== url=====", url)
      if (url) {
        window.open(url)
      }
    }
    const forgotPassword = () => {
      const loading = ElLoading.service({
        lock: true,
      })
      axios.request({
        method: 'POST',
        url: '/m/forgotPassword/',
        data: {
          email: data.userName
        }
      }).then(res => {
        console.log('res:', res);
        if (res.data.result == 'succeeded') {
          loading.close();
          ElMessageBox.alert('We\'ve sent you an email to update your password. If you don\'t see it in your Inbox, please check your Spam folder.',  {
            confirmButtonText: 'OK',
          })
        }
      }).catch(err => {
        loading.close();
        console.log(err);
      })
    }
    return {
      fun,
      ...toRefs(data),
      showCheckBox,
      next,
      login,
      client,
      nextStep,
      goLink,
      forgotPassword
    };
  },
});
